import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput, ImageInput,
  ImageField
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import RichTextInput from 'ra-input-rich-text';
import CustomImageDisplay from '../../common/custom-image-display';

export const IndustriesEdit = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <Edit {...propsObj} title="Action Edit" undoable={false}>
      <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">
        <TextInput source="name" fullWidth={true} validate={[required()]} />

        <ReferenceInput perPage={2000} label="Capabilities" source="categoryId" reference="services" fullWidth={true} validate={[required()]} sort={{ field: 'name', order: 'ASC' }}>
          <SelectInput optionText="name" />
        </ReferenceInput>

        <RichTextInput
          toolbar={[
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['image', 'link'],
            ['clean']]}
          source="description" fullWidth={true} />
        <ImageInput source="imageUrl" fullWidth={true}
          label="Logo" accept="image/*" className="image" >
          <ImageField source="imageUrl" title="Image" />
        </ImageInput>
        <CustomImageDisplay />
      </SimpleForm>
    </Edit>
  );
};
