import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,ImageInput,
  ImageField
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';
import { FormToolbar } from "../../common/FormToolbar";

export const ServicesCreate = (props) => {
  return (
    <Create title="Services Create" undoable={false} {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        <RichTextInput source="description" fullWidth={true} />
        <ImageInput source="imageUrl" fullWidth={true}
          label="Logo" accept="image/*" className="image" >
          <ImageField source="imageUrl" title="Image" />
        </ImageInput>

        <ImageInput source="imageUrl1" fullWidth={true}
          label="Detail Page Image" accept="image/*" className="image" >
          <ImageField source="imageUrl1" title="Detail Page Image" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
